import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { clearInvite, connectInvited } from '../../invite/redux/inviteSlice'
import { setOrganisation } from '../../Organisation/redux/organisationSlice'
import ApiError from '../../../utils/errors/ApiError'
import { NewFeaturePages } from '../../NewFeaturesTour/newFeaturesSeenSlice'
import { OrganizationRole } from '../../Organisation/OrganizationInterface'
import { RootState } from '../../../store'

interface AuthenticatedJwtToken extends JwtPayload, User {
  exp: NonNullable<JwtPayload['exp']>
}

export interface UserGroup {
  id: string
  name: string
}

export interface NewFeaturesSeen {
  meeting: boolean
  planning: boolean
  drive: boolean
  team: boolean
  home: boolean
}

interface User {
  email?: string
  sessionExpiresAt?: number
  refreshTokenExpiresAt?: number
  groups?: UserGroup[]
  firstName?: string
  lastName?: string
  organizationRole?: OrganizationRole
  organizationName?: string
  tel?: string
  lang?: string
  newFeaturesSeen?: NewFeaturesSeen
}

interface State extends User {
  jwt?: string
  refreshJwt?: string
  attemptAuthStatus: string
  attemptSignupStatus: string
  attemptReinitPasswordStatus: string
  hasBeenDisconnected: boolean
}

export const initialState: State = {
  attemptAuthStatus: 'idle',
  attemptSignupStatus: 'idle',
  attemptReinitPasswordStatus: 'idle',
  hasBeenDisconnected: false,
}

/**
 * Auth user with email and password.
 */
export const attemptAuth = createAsyncThunk(
  'auth/attemptAuth',
  async (payload: { email: string; password: string; lang: string }, { dispatch }) => {
    dispatch(clearInvite())
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/users/auth/login`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      },
    )

    const body = await response.json()
    if (!response.ok) {
      const message = body.message || 'unknown_error'
      throw new ApiError(response.status.toString(), body, message)
    }

    try {
      const payload: any = jwtDecode(body.token)
      dispatch(setJWT(body))
      dispatch(
        setOrganisation({
          id: payload['organizationId'],
          name: payload['organizationName'],
        }),
      )
    } catch (e) {
      console.error(e)
      throw e
    }
  },
)

/**
 * Auth user with Secure & Access token.
 */
export const attemptSafeaccessAuth = createAsyncThunk(
  'auth/attemptSafeaccessAuth',
  async (payload: { token: string }, { dispatch }) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/users/auth/login/securenaccess`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      },
    )

    if (!response.ok) {
      const reason = (await response.json()).reason || 'unknown_error'
      throw new ApiError(reason)
    }

    try {
      const body = await response.json()
      const payload: any = jwtDecode(body.token)
      dispatch(setJWT(body))
      dispatch(
        setOrganisation({
          id: payload['organizationId'],
          name: payload['organizationName'],
        }),
      )
    } catch (e) {
      console.error(e)
      throw e
    }
  },
)

export const attemptRefreshTokens = createAsyncThunk(
  'auth/attemptRefreshTokens',
  async (payload, { getState, dispatch }) => {
    console.log('auth/attemptRefreshTokens')
    const { auth } = getState() as RootState
    // FIXME: complete implementation dispatch(clearInvite())
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/users/auth/refresh`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.refreshJwt}`,
        },
        body: JSON.stringify(payload),
      },
    )

    if (!response.ok) {
      const reason = (await response.json()).reason || 'unknown_error'
      throw new ApiError(reason)
    }

    try {
      const body = await response.json()
      const payload: any = jwtDecode(body.token)
      dispatch(setJWT(body))
      dispatch(
        setOrganisation({
          id: payload['organizationId'],
          name: payload['organizationName'],
        }),
      )
    } catch (e) {
      console.error(e)
      throw e
    }
  },
)

/**
 * Sign up user.
 */
export const attemptSignup = createAsyncThunk(
  'auth/attemptSignup',
  async (payload: {
    email: string
    firstName: String
    lastName: string
    tel: string
    lang: string
    password: string
  }) => {
    const response = await fetch(
      process.env.REACT_APP_MEDIA_BASE_URL + '/participant/signup',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      },
    )

    if (!response.ok) {
      const reason = (await response.json()).reason || 'unknown_error'
      throw new ApiError(reason)
    }

    return await response.json()
  },
)

/**
 * Reinit password.
 */
export const attemptReinitPassword = createAsyncThunk(
  'auth/attemptReinitPassword',
  async ({ email }: { email: string }) => {
    await fetch(process.env.REACT_APP_BASE_USERS_URL + '/reset-password/demand', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    })
  },
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setJWT: (
      state,
      { payload }: PayloadAction<{ token: string; refreshToken: string }>,
    ) => {
      const user: any = jwtDecode(payload.token)
      const refreshTokenDecoded = jwtDecode<AuthenticatedJwtToken>(payload.refreshToken)

      state.jwt = payload.token
      state.refreshJwt = payload.refreshToken
      state.sessionExpiresAt = user.exp * 1000
      state.refreshTokenExpiresAt = refreshTokenDecoded.exp * 1000
      state.email = user.sub
      state.firstName = user.firstName
      state.lastName = user.lastName
      state.tel = user.tel
      state.organizationRole = user.organizationRole
      state.organizationName = user.organizationName
      state.lang = user.lang
      state.groups = user.groups
      state.newFeaturesSeen = {
        meeting: user.meetingNewFeaturesSeen,
        planning: user.planningNewFeaturesSeen,
        drive: user.driveNewFeaturesSeen,
        team: user.teamNewFeaturesSeen,
        home: user.homeNewFeaturesSeen,
      }
    },
    idleAttemptAuthStatus: (state) => {
      state.attemptAuthStatus = 'idle'
    },
    idleAttemptSignupStatus: (state) => {
      state.attemptSignupStatus = 'idle'
    },
    idleAttemptReinitPasswordStatus: (state) => {
      state.attemptReinitPasswordStatus = 'idle'
    },
    logout: () => {
      // Handled in store.ts in rootReducer
    },
    setNewFeaturesSeen: (state, { payload }: PayloadAction<NewFeaturePages>) => {
      state.newFeaturesSeen = {
        meeting: payload === NewFeaturePages.MEETING,
        planning: payload === NewFeaturePages.PLANNING,
        drive: payload === NewFeaturePages.DRIVE,
        team: payload === NewFeaturePages.TEAM,
        home: payload === NewFeaturePages.HOME,
      }
      switch (payload) {
      }
    },
  },
  extraReducers: {
    [attemptAuth.pending.type]: (state) => {
      state.attemptAuthStatus = 'loading'
    },
    [attemptAuth.fulfilled.type]: (state, action) => {
      state.attemptAuthStatus = 'success'
    },
    [attemptAuth.rejected.type]: (state, action) => {
      const error = action.error
      state.jwt = undefined
      state.email = undefined
      state.groups = undefined
      state.firstName = undefined
      state.lastName = undefined
      state.tel = undefined
      state.lang = undefined
      state.organizationName = undefined
      state.organizationRole = undefined
      state.attemptAuthStatus =
        error.name === 'ApiError' ? error.message : 'unknown_error'
    },

    [attemptSafeaccessAuth.pending.type]: (state) => {
      state.attemptAuthStatus = 'loading'
    },
    [attemptSafeaccessAuth.fulfilled.type]: (state, action) => {
      state.attemptAuthStatus = 'success'
    },
    [attemptSafeaccessAuth.rejected.type]: (state, action) => {
      const error = action.error
      state.jwt = undefined
      state.email = undefined
      state.groups = undefined
      state.firstName = undefined
      state.lastName = undefined
      state.tel = undefined
      state.lang = undefined
      state.organizationName = undefined
      state.organizationRole = undefined
      state.attemptAuthStatus =
        error.name === 'ApiError' ? error.message : 'unknown_error'
    },

    [attemptSignup.pending.type]: (state) => {
      state.attemptSignupStatus = 'loading'
    },
    [attemptSignup.fulfilled.type]: (state, action) => {
      state.attemptSignupStatus = 'success'
      state.jwt = action.payload.token
      const user: User = jwtDecode(action.payload.token)
      state.email = user.email
      state.firstName = user.firstName
      state.lastName = user.lastName
      state.tel = user.tel
      state.lang = user.lang
    },
    [attemptSignup.rejected.type]: (state, action) => {
      const error = action.error
      state.jwt = undefined
      state.email = undefined
      state.firstName = undefined
      state.lastName = undefined
      state.tel = undefined
      state.lang = undefined
      state.attemptSignupStatus =
        error.name === 'ApiError' ? error.message : 'unknown_error'
    },

    [attemptReinitPassword.pending.type]: (state, action) => {
      state.attemptReinitPasswordStatus = 'loading'
    },
    [attemptReinitPassword.fulfilled.type]: (state, action) => {
      state.attemptReinitPasswordStatus = 'success'
    },
    [attemptReinitPassword.rejected.type]: (state, action) => {
      const error = action.error
      state.attemptReinitPasswordStatus =
        error.name === 'ApiError' ? error.message : 'unknown_error'
    },

    [connectInvited.pending.type]: (state) => {
      state.jwt = undefined
      state.email = undefined
      state.firstName = undefined
      state.lastName = undefined
      state.tel = undefined
      state.lang = undefined
    },
  },
})

export const {
  setJWT,
  setNewFeaturesSeen,
  idleAttemptAuthStatus,
  idleAttemptSignupStatus,
  idleAttemptReinitPasswordStatus,
  logout,
} = authSlice.actions

export default authSlice.reducer
