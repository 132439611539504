import React, { useEffect, useState } from 'react'
import { Button, Col, Divider, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout/Layout'
import { AppDispatch, RootState } from '../../store'
import './Account.scss'
import {
  attemptModify,
  idleAttemptModifyStatus,
  fetchPreferences,
} from './redux/accountSlice'
import AccountMenu from './AccountMenu'
import AccountGeneral from './AccountGeneral'
import AccountNotifications from './AccountNotifications'
import AccountPassword from './AccountPassword'
import DesktopNotificationSettings from './DesktopNotificationSettings'
import { useToastContext } from '../../components/Toast/ToastContext'

export enum AccountItem {
  General = 'general',
  Password = 'password',
  Notifications = 'notifications',
}

function Account() {
  const { i18n, t } = useTranslation(['account', 'reinitpassword'])
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const auth = useSelector((state: RootState) => state.auth)
  const notification = useSelector((state: RootState) => state.account.notification)
  const attemptModifyStatus = useSelector(
    (state: RootState) => state.account.attemptModifyStatus,
  )
  const [form] = Form.useForm()
  const [accountItem, setAccountItem] = useState<AccountItem>(AccountItem.General)

  const mergeNotification = (notificationToMerge: { key: string; checked?: boolean }) => {
    const exCheckedValue = notification?.find(
      (n) => n.key === notificationToMerge.key,
    )?.checked
    const checked =
      notificationToMerge.checked != undefined
        ? notificationToMerge.checked
        : exCheckedValue != undefined
          ? exCheckedValue
          : true
    return checked
  }

  const onFinish = ({
    firstName,
    lastName,
    tel,
    oldPassword,
    newPassword,
    lang,
    emailMeetingReminder,
    emailMeetingInvitation,
    emailMeetingUpdate,
    emailMeetingVote,
    emailMeetingJoin,
  }: {
    firstName: string
    lastName: string
    tel: string
    oldPassword?: string
    newPassword?: string
    lang: string
    emailMeetingReminder: boolean
    emailMeetingInvitation: boolean
    emailMeetingUpdate: boolean
    emailMeetingVote: boolean
    emailMeetingJoin: boolean
  }) => {
    dispatch(
      attemptModify({
        firstName: firstName != undefined ? firstName : auth.firstName || '',
        lastName: lastName != undefined ? lastName : auth.lastName || '',
        tel: tel != undefined ? tel : auth.tel || '',
        newPassword,
        oldPassword,
        lang: lang || auth.lang || '',
        notification: [
          {
            key: 'emailMeetingReminder',
            checked: mergeNotification({
              key: 'emailMeetingReminder',
              checked: emailMeetingReminder,
            }),
          },
          {
            key: 'emailMeetingInvitation',
            checked: mergeNotification({
              key: 'emailMeetingInvitation',
              checked: emailMeetingInvitation,
            }),
          },
          {
            key: 'emailMeetingUpdate',
            checked: mergeNotification({
              key: 'emailMeetingUpdate',
              checked: emailMeetingUpdate,
            }),
          },
          {
            key: 'emailMeetingVote',
            checked: mergeNotification({
              key: 'emailMeetingVote',
              checked: emailMeetingVote,
            }),
          },
          {
            key: 'emailMeetingJoin',
            checked: mergeNotification({
              key: 'emailMeetingJoin',
              checked: emailMeetingJoin,
            }),
          },
        ],
      }),
    )
  }

  useEffect(() => {
    dispatch(fetchPreferences({}))
    form.setFieldsValue({
      email: auth.email,
      firstName: auth.firstName,
      lastName: auth.lastName,
      tel: auth.tel,
      lang: i18n.language,
      licence: 'Enterprise',
      cps: true,
      newPassword: '',
      confirm: '',
    })
  }, [auth.jwt])

  useEffect(() => {
    form.setFieldsValue({
      emailMeetingReminder: notification?.find(
        (notif) => notif.key == 'emailMeetingReminder',
      )
        ? notification?.find((notif) => notif.key == 'emailMeetingReminder')?.checked
        : true,
      emailMeetingInvitation: notification?.find(
        (notif) => notif.key == 'emailMeetingInvitation',
      )
        ? notification?.find((notif) => notif.key == 'emailMeetingInvitation')?.checked
        : true,
      emailMeetingUpdate: notification?.find((notif) => notif.key == 'emailMeetingUpdate')
        ? notification?.find((notif) => notif.key == 'emailMeetingUpdate')?.checked
        : true,
      emailMeetingVote: notification?.find((notif) => notif.key == 'emailMeetingVote')
        ? notification?.find((notif) => notif.key == 'emailMeetingVote')?.checked
        : true,
      emailMeetingJoin: notification?.find((notif) => notif.key == 'emailMeetingJoin')
        ? notification?.find((notif) => notif.key == 'emailMeetingJoin')?.checked
        : true,
    })
  }, [notification])

  useEffect(() => {
    switch (attemptModifyStatus) {
      case 'success':
        ToastOpen({
          message: t('Your account has been successfully modified.'),
          type: 'success',
        })
        break
      case 'unknown_error':
        ToastOpen({
          message: t('An error occurred.'),
          type: 'error',
        })
        break
      case 'PASSWORD_MISMATCH':
        form.setFields([
          {
            name: 'oldPassword',
            errors: [t('Password mismatch', { ns: 'reinitpassword' })],
          },
        ])
        break
    }

    return () => {
      dispatch(idleAttemptModifyStatus())
    }
  }, [attemptModifyStatus])

  function renderAccount() {
    return (
      <div>
        <div className="layout-header-bottom">
          <h4 className="layout-list-header">
            {accountItem === AccountItem.General && t('General information')}
            {accountItem === AccountItem.Notifications && t('Notifications management')}
            {accountItem === AccountItem.Password && t('Password management')}
          </h4>
        </div>
        <Divider />
        <Form
          className="account-modify-form mb-1rem"
          id="account-modify"
          form={form}
          autoComplete="off"
          layout="vertical"
          onFinish={onFinish}
          disabled={attemptModifyStatus === 'loading'}
          scrollToFirstError={true}
        >
          {accountItem === AccountItem.General && <AccountGeneral />}
          {accountItem === AccountItem.Notifications && <AccountNotifications />}
          {accountItem === AccountItem.Password && <AccountPassword />}
          <div className="d-flex d-flex-center mt-1rem">
            <Button
              loading={attemptModifyStatus === 'loading'}
              form="account-modify"
              type="primary"
              htmlType="submit"
            >
              {t('Validate', { ns: 'common' })}
            </Button>
          </div>
        </Form>
        {accountItem === AccountItem.Notifications && (
          <div>
            <DesktopNotificationSettings />
          </div>
        )}
      </div>
    )
  }

  function renderAccountMenu() {
    return <AccountMenu accountItem={accountItem} setAccountItem={setAccountItem} />
  }

  return (
    <Layout
      title={t('Account')}
      headerTitle={<h1 className="title">{t('Account')}</h1>}
      menu={renderAccountMenu()}
      content={renderAccount()}
      footer={false}
    />
  )
}

export default Account
